<template>
    <div class="modal fade" id="serviceProviderModal" tabindex="-1" aria-labelledby="serviceProviderModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="serviceProviderModalLabel">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form @submit.prevent="saveServiceProvider">
                    <div class="modal-body">
                        <div class="mb-3">
                            <drop-down-list :label="trans('instance')" id="instance" v-model="serviceProvider.instance" :options="instancesList" :errors="validationErrors['instance']" :form-submitted="submittedForm" />
                        </div>
                        <div class="mb-3">
                            <text-input :label="trans('name')" id="name" v-model="serviceProvider.name" :errors="validationErrors['name']" :form-submitted="submittedForm" />
                        </div>
                        <div class="mb-3">
                            <email-input :label="trans('email_address')" id="email" v-model="serviceProvider.email" :errors="validationErrors['email']" :form-submitted="submittedForm" />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ trans('close') }}</button>
                        <button type="submit" class="btn btn-primary" :disabled="!serviceProvider.name || !serviceProvider.email || !serviceProvider.instance">{{ trans('save') }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import TextInput from '../common/inputs/TextInput.vue';
    import EmailInput from '../common/inputs/EmailInput.vue';
    import DropDownList from '../common/inputs/DropDownList.vue';

    export default {
        components: {
            TextInput,
            EmailInput,
            DropDownList
        },
        props: {
            modalType: {
                type: String
            },
            modalTitle: {
                type: String
            },
            url: {
                type: String
            },
            instances: {
                type: Object
            },
            serviceProviderData: {
                type: Object
            }
        },
        data() {
            return {
                instancesList: this.instances,
                serviceProvider: this.serviceProviderData,
                submittedForm: false,
                validationErrors: [],
            }
        },
        mounted() {
            let serviceProviderModalEl = document.getElementById('serviceProviderModal');
            let self = this;
            
            serviceProviderModalEl.addEventListener('hidden.bs.modal', function (event) {
                self.resetForm();
                self.submittedForm = false;
                self.validationErrors = [];
            });
        },
        methods: {
            trans(str) {
                return trans(str);
            },
            saveServiceProvider() {
                let request = postForm(this.url, null, {id: this.serviceProvider.id, instance: this.serviceProvider.instance, name: this.serviceProvider.name, email: this.serviceProvider.email});
                let self = this;

                request.then(function (response) {
                    toastMessage('success', response.message);
                    self.$emit('updateServiceProviders', response.serviceProviderData);
                    self.hideModal();
                    self.submittedForm = false;
                    self.validationErrors = [];
                });

                request.catch(function(error) {
                    self.submittedForm = true;
                    
                    if (error.response.status == 422) {
                       self.validationErrors = error.response.data.errors; 
                    }
                });
            },
            hideModal() {
                let serviceProviderModalEl = document.getElementById('serviceProviderModal');
                let serviceProviderModal = bootstrap.Modal.getInstance(serviceProviderModalEl);
                serviceProviderModal.hide();
            },
            resetForm() {
                this.serviceProvider.instance = '';
                this.serviceProvider.name = '';
                this.serviceProvider.email = '';
                this.submittedForm = false;
                this.validationErrors = []
            }
        }
    }
</script>
