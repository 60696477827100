require('./bootstrap');

import { createApp } from 'vue';
import FileUpload from './components/FileUpload.vue';
import MainMenu from './components/MainMenu.vue';
import CreateAnalysisForm from './components/client/CreateAnalysisForm.vue';
import OrganizationList from './components/service_provider/OrganizationList.vue';
import UserList from './components/service_provider/UserList.vue';
import NewAnalyses from './components/common/NewAnalyses.vue';
import AnalysesInProcess from './components/common/AnalysesInProcess.vue';
import FinishedAnalyses from './components/common/FinishedAnalyses.vue';
import VueSimpleAlert from "vue3-simple-alert-next";
import InstanceList from './components/admin/InstanceList.vue';
import InstanceForm from './components/admin/InstanceForm.vue';
import ServiceProviderList from './components/admin/ServiceProviderList.vue';
import Profile from './components/profile/Profile.vue';
import ChangePassword from './components/profile/ChangePassword.vue';

const app = createApp({
    components: {
        FileUpload,
        MainMenu,
        CreateAnalysisForm,
        OrganizationList,
        UserList,
        NewAnalyses,
        AnalysesInProcess,
        FinishedAnalyses,
        InstanceList,
        InstanceForm,
        ServiceProviderList,
		Profile,
        ChangePassword,
    }
});

app.use(VueSimpleAlert);
app.mount("#app");