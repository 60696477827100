<template>
    <div class="row">
        <div class="col-md-6">
            <h3>{{ trans('users') }}</h3>
        </div>
        <div class="col-md-6 text-end">
            <a href="" class="btn btn-primary" @click.prevent="showCreateModal">{{ trans('create_user') }}</a>
        </div>
    </div>
    <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">{{ trans('user_name') }}</th>
                    <th scope="col">{{ trans('organization_name') }}</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="users.length > 0" v-for="(user, index) in users">
                    <th scope="row">{{ (index + 1) }}</th>
                    <td>{{ user.name }}</td>
                    <td>{{ user.organization }}</td>
                    <td><a href="" class="btn btn-sm btn-info" v-if="!user.password" @click.prevent="resendLink(user.email)">{{ trans('resend_link') }}</a></td>
                    <td><a href="" class="btn btn-sm btn-secondary" @click.prevent="showEditModal(user)">{{ trans('edit') }}</a></td>
                    <td><a href="" class="btn btn-sm btn-danger" @click.prevent="deleteUser(user.id, user.name)">{{ trans('delete') }}</a></td>
                </tr>
                <tr v-else>
                    <td colspan="4" class="text-center">{{ trans('no_data_found') }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <pagination v-if="totalPages > 0" @pageChanged="onChangePage" :total-pages="totalPages" :per-page="perPage" :current-page="currentPage" />
    <user-form @updateUserData="changeUserData" :modal-type="modalType" :modal-title="modalTitle" :organizations="organizations" :user="user" :url="url" />
</template>

<script>
    import UserForm from './UserForm.vue';
    import {Modal} from 'bootstrap';
    import Pagination from '../Pagination.vue';

    export default {
        components: {
            UserForm,
            Pagination
        },
        props: {
            usersData: {
                type: Object
            },
            organizationsData: {
                type: Object
            },
            totalRows: {
                type: Number
            },
        },
        data() {
            return {
                users: this.usersData,
                organizations: this.organizationsData,
                totalNumRows: this.totalRows,
                modalType: 'create',
                modalTitle: this.trans('create_user'),
                url: 'create-user',
                user: {
                    organization: '',
                    id: null,
                    name: '',
                    email: ''
                },
                perPage: 10,
                currentPage: 1,
            }
        },
        computed: {
            totalPages() {
                return Math.ceil(this.totalNumRows / this.perPage);
            }
        },
        methods: {
            trans(str) {
                return trans(str);
            },
            showCreateModal() {
                let createModal = new Modal(document.getElementById('userModal'));
                createModal.show();
                this.modalType = 'create';
                this.modalTitle = this.trans('create_user');
                this.url = 'create-user';
            },
            showEditModal(user) {
                let editModal = new Modal(document.getElementById('userModal'));
                editModal.show();
                this.modalType = 'edit';
                this.modalTitle = this.trans('edit_user');
                this.url = 'edit-user';
                this.user.organization = user.organizationId;
                this.user.id = user.id;
                this.user.name = user.name;
                this.user.email = user.email;
            },
            changeUserData(val) {
                if (this.modalType == 'create') {
                    let pageOffset = this.totalPages > 0 ? this.totalPages : 1;
                    this.onChangePage(pageOffset);
                } else {
                    for (let u in this.users) {
                        if (this.users[u].id == val.id) {
                            this.users[u].id = val.id;
                            this.users[u].name = val.name;
                            this.users[u].email = val.email;
                            this.users[u].password = val.password;
                            this.users[u].organizationId = val.organizationId;
                            this.users[u].organization = val.organization;
                        }
                    }
                }
            },
            resendLink(email) {
                this.$confirm(
                    this.trans('resend_link_confirm'), 
                    '', 
                    '', 
                    {
                        title: '<h3 class="mb-0">'+ this.trans('resend_link') +'</h3>',
                        buttonsStyling: false, 
                        customClass: {
                            confirmButton: 'btn btn-primary m-1', 
                            cancelButton: 'btn btn-secondary m-1'
                        }
                    }
                ).then(() => {
                    let request = post('/service-provider/resend-link', {email: email});
                    let self = this;

                    request.then(function (response) {
                        toastMessage('success', response.data.message);
                    });
                }).catch((error) => {
                    
                });
            },
            deleteUser(id, name) {
                this.$confirm(
                    this.trans('delete_user_confirm'), 
                    '', 
                    '', 
                    {
                        title: '<h4 class="mb-0">'+ this.trans('delete_user') +': '+ name +'</h4>',
                        buttonsStyling: false, 
                        customClass: {
                            confirmButton: 'btn btn-primary m-1', 
                            cancelButton: 'btn btn-secondary m-1'
                        }
                    }
                ).then(() => {
                    let request = post('/service-provider/delete-user', {id: id});
                    let self = this;

                    request.then(function (response) {
                        for (let org in self.users) {
                            if (self.users[org].id == response.data.id) {
                                self.users.splice(org, 1);
                            }
                        }

                        if (self.users.length == 0) {
                            self.totalNumRows = 0;
                        }

                        toastMessage('success', response.data.message);
                    });
                }).catch((error) => {
                    
                });
            },
            onChangePage(page) {
                this.currentPage = page;
                let offset = this.currentPage - 1;

                let request = get('/service-provider/users?offset='+ offset);
                let self = this;

                request.then(function (response) {
                    self.users = response.usersData;
                    self.organizations = response.organizationsData;
                    self.totalNumRows = response.totalRows;
                });
            }
        },
    }
</script>
