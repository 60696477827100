<template>
    <button type="button" id="selectFiles" :class="[buttonClasses]">{{ buttonText }}</button>
    <div id="uppyDashboradFiles">
        <ul id="uppyDashboradFileList" class="list-unstyled my-2">
            <li v-for="(file, index) in uploadedFiles">
                <a href="" @click.prevent="downloadFile($event, file.downloadUrl, file.originalName)">{{ file.originalName }}</a>
                <a href="" class="ms-3" @click.prevent="removeUploadedFile"><i class="fas fa-times-circle"></i></a>
            </li>
        </ul>
    </div>
</template>

<script>
    import Uppy from "@uppy/core";
    import XHRUpload from "@uppy/xhr-upload";
    import Dashboard from "@uppy/dashboard";
    import AwsS3 from '@uppy/aws-s3';
    import "@uppy/core/dist/style.css";
    import "@uppy/dashboard/dist/style.css"

    export default {
        props: {
            maxFileSize: Number,
            allowedFileTypes: Array,
            maxNumberOfFiles: Number,
            buttonClasses: String,
            buttonText: String,
            uploadUrl: String,
            deleteUrl: String,
            customId: null,
            isUploadInForm: Boolean,
            isStartedFileUpload: Boolean,
            existingUploadedFiles: Array,
            uploadedSuccessResponse: Object
        },
        emits: ['uploaded', 'updateIsStartedFileUpload', 'updateFile'],
        data() {
            return {
                uploadedFiles: this.existingUploadedFiles ? this.existingUploadedFiles : [],
                uploadSuccessResponse: this.uploadedSuccessResponse && this.uploadedSuccessResponse.data && this.uploadedSuccessResponse.data.s3FileId ? this.uploadedSuccessResponse : {},
            }
        },
        mounted() {
            const uppy = new Uppy({
                restrictions: {
                    maxFileSize: this.maxFileSize,
                    allowedFileTypes: this.allowedFileTypes,
                    maxNumberOfFiles: this.maxNumberOfFiles
                }
            }).use(Dashboard, {
                proudlyDisplayPoweredByUppy: false,
                trigger: "#selectFiles",
                closeModalOnClickOutside: true,
                closeAfterFinish: true
            }).use(AwsS3, {
                //shouldUseMultipart: (file) => file.size > 5 * 2 ** 20,
                getUploadParameters(file) {
                return fetch('/generate-presigned-url', {
                    method: 'POST',
                    body: JSON.stringify({
                        fileName: file.name,
                        fileType: file.type,
                    }),
                    headers: {
                        accept: 'application/json',
                        'content-type': 'application/json',
                        'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').content
                    }
                }).then((response) => {
                    if(response.ok){
                        return response.json();
                    }
                }).then(json => {
                    let preSignedUrl = json.presignedUrl;
                    let s3Path = json.s3Path;
                    
                    return {
                        method: 'PUT',
                        url: preSignedUrl,
                        fields: {},
                        headers: {
                        'Content-Type': file.type,
                        },
                    }
                }).catch((error) => {
                    console.log("error", error);
                });
                },
                timeout: 0
            });

            uppy.on('upload-success', (file, data) => {
                let formData = new FormData()

                formData.append('customId', this.customId);
                formData.append('s3FileName', file.meta.name);
                formData.append('s3FileType', file.meta.type);
                formData.append('s3FileUrl', data.uploadURL);

                let request = post(this.uploadUrl, formData);
                let self = this;

                request.then(function (response) {
                    if (self.isUploadInForm) {
                        self.$emit('updateIsStartedFileUpload', false);
                        self.$emit('updateFile', response.data.s3FileId);
                    } else {
                        self.$emit('uploaded', true);
                    }

                    self.uploadedFiles = [];
                    self.uploadedFiles.push({'originalName': file.meta.name, 'downloadUrl': '/file/'+ response.data.s3FileId +'/downloadUrl'});
                    self.uploadSuccessResponse = response;
                    toastMessage('success', response.data.message);
                });
            });

            uppy.on("complete", result => {
                console.log('successful files:', result.successful);
	            console.log('failed files:', result.failed);
            });

            uppy.on('error', (error) => {
                console.error(error.stack);
            });
        },
        methods: {
            trans(str) {
                return trans(str);
            },
            downloadFile(event, fileUrl, fileName) {
                event.preventDefault();
                let request = get(fileUrl);
                request.then(response => {
                    const link = document.createElement('a');
                    link.href = response;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
            },
            removeUploadedFile(e) {
                e.preventDefault();
               
                this.$confirm(
                    this.trans('delete_file_confirm'), 
                    '', 
                    '', 
                    {
                        title: '<h3 class="mb-0">'+ this.trans('delete_file') +'</h3>',
                        buttonsStyling: false, 
                        customClass: {
                            confirmButton: 'btn btn-primary m-1', 
                            cancelButton: 'btn btn-secondary m-1'
                        }
                    }
                ).then(() => {
                    let request = post(this.deleteUrl, {fileId: this.uploadSuccessResponse.data.s3FileId});
                    let self = this;

                    request.then(function (response) {
                        self.uploadedFiles = [];
                        
                        if (self.isUploadInForm) {
                            self.$emit('updateFile', '');
                        } else {
                            self.$emit('uploaded', false);
                        }
                        
                        toastMessage('success', response.data.message);
                    });
                }).catch((error) => {
                    
                });
            }
        }
    }
</script>
