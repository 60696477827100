<template>
    <form @submit.prevent="createAnalysis">
        <div class="mb-3 mt-3">
            <h5>{{ trans('analysis_type') }}</h5>
            <RadioButtonGroup name="type" v-model="analysis.type" :options="analysisTypeOptions" :errors="validationErrors['analysis.type']" :form-submitted="submittedForm" />
        </div>
        <div class="mb-3">
            <h5>{{ trans('recording') }}</h5>
            <div class="row">
                <div class="col-md-12">
                    <label class="form-label">{{ trans('pdf_edf_file_upload') }}</label>
                    <input type="hidden" v-model="analysis.recording_file">
                </div>
                <div class="col-md-12">
                    <file-upload @updateIsStartedFileUpload="updateIsStartedFileUpload" @updateFile="updateFile" buttonClasses="btn btn-info" :buttonText="buttonText" :maxFileSize="this.maxFileSize" :allowedFileTypes="this.allowedFileTypes" :maxNumberOfFiles="this.maxNumberOfFiles" uploadUrl="/client/upload-recording-file" deleteUrl="/client/delete-file" isStartedFileUpload isUploadInForm />
                    <div class="invalid-feedback" :class="[validationErrors['analysis.recording_file'] ? 'd-block' : '']">
                        {{ validationErrors['analysis.recording_file'] ? validationErrors['analysis.recording_file'][0] : '' }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <DatePickerInput :label="trans('recording_start_date')" :v-model-value="analysis.recording_start_date" @inputDatePicker="(newValue) => {analysis.recording_start_date = newValue}" :errors="validationErrors['analysis.recording_start_date']" :form-submitted="submittedForm" />
                </div>
                <div class="col-md-4">
                    <TimePickerInput :label="trans('recording_start_time')" :v-model-value="analysis.recording_start_time" @inputTimePicker="(newValue) => {analysis.recording_start_time = newValue}" :errors="validationErrors['analysis.recording_start_time']" :form-submitted="submittedForm" />
                </div>
                <div class="col-md-4">
                    <DropDownList :label="trans('duration_of_recording')" id="durationOfRecording" v-model="analysis.recording_duration" :options="durationOfRecordingOptions" :errors="validationErrors['analysis.recording_duration']" :form-submitted="submittedForm" />
                </div>
            </div>
        </div>
        <div class="mb-3">
           <h5>{{ trans('basic_information') }}</h5>
           <div class="row">
                <div class="col-md-12">
                    <RadioButtonGroup name="gender" v-model="analysis.gender" :options="genderOptions" :errors="validationErrors['analysis.gender']" :form-submitted="submittedForm" />
                </div>
           </div>
           <div class="row">
                <div class="col-md-4">
                    <DatePickerInput :label="trans('date_of_birth')" :v-model-value="analysis.birth_date" @inputDatePicker="(newValue) => {analysis.birth_date = newValue}" :errors="validationErrors['analysis.birth_date']" :form-submitted="submittedForm" />
                </div>
                <div class="col-md-4">
                    <TextInput :label="trans('length')" id="length" v-model="analysis.length" :errors="validationErrors['analysis.length']" :form-submitted="submittedForm" />
                </div>
                <div class="col-md-4">
                    <TextInput :label="trans('weight')" id="weight" v-model="analysis.weight" :errors="validationErrors['analysis.weight']" :form-submitted="submittedForm" />
                </div>
            </div>
        </div>
        <div class="mb-3">
           <h5>{{ trans('lifestyles') }}</h5>
           <div class="row">
                <div class="col-md-6">
                    <h6 class="mt-2">{{ trans('usage_of_alcohol') }}</h6>
                    <RadioButtonGroup name="alcoholUsage" v-model="analysis.alcohol_usage" :options="options" :errors="validationErrors['analysis.alcohol_usage']" :form-submitted="submittedForm" />
                </div>
                <div class="col-md-6">
                    <h6 class="mt-2">{{ trans('smoking') }}</h6>
                    <RadioButtonGroup name="smoking" v-model="analysis.smoking" :options="options" :errors="validationErrors['analysis.smoking']" :form-submitted="submittedForm" />
                </div>
            </div>
        </div>
        <div class="mb-3">
            <h5>{{ trans('medication') }}</h5>
            <div class="row">
                <div class="col-md-12">
                    <TextArea :label="trans('constant_medication')" id="constantMedication" v-model="analysis.constant_medication" :errors="validationErrors['analysis.constant_medication']" :form-submitted="submittedForm" />
                </div>
                <div class="col-md-12">
                    <label class="form-label d-block mt-2">{{ trans('research_day_medication') }}</label>
                    <RadioButtonGroup name="isResearchDayMedication" v-model="analysis.is_research_day_medication" :options="researchDayMedicationOptions" :errors="validationErrors['analysis.is_research_day_medication']" :form-submitted="submittedForm" />
                </div>
                <div class="col-md-12" v-if="analysis.is_research_day_medication == 1">
                    <TextArea id="researchDayMedication" v-model="analysis.research_day_medication" :errors="validationErrors['analysis.research_day_medication']" :form-submitted="submittedForm" />
                </div>
            </div>
        </div>
        <div class="mb-3">
            <h5>{{ trans('heart_health') }}</h5>
            <div class="row">
                <div class="col-md-12">
                    <h6 class="mt-2">{{ trans('diagnosed_heart_disease') }}</h6>
                    <RadioButtonGroup name="isDiagnosedHeartDisease" v-model="analysis.is_diagnosed_heart_disease" :options="noYesWhatOptions" :errors="validationErrors['analysis.is_diagnosed_heart_disease']" :form-submitted="submittedForm" />
                    <div class="col-md-12" v-if="analysis.is_diagnosed_heart_disease == 1">
                        <TextInput id="diagnosedHeartDisease" v-model="analysis.diagnosed_heart_disease" :errors="validationErrors['analysis.diagnosed_heart_disease']" :form-submitted="submittedForm" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h6 class="mt-2">{{ trans('sudden_death_arrhythmias_myocardial_infarction') }}</h6>
                    <RadioButtonGroup name="isSuddenDeath" v-model="analysis.is_sudden_death" :options="noYesWhatOptions" :errors="validationErrors['analysis.is_sudden_death']" :form-submitted="submittedForm" />
                    <div class="col-md-12" v-if="analysis.is_sudden_death == 1">
                        <TextInput id="suddenDeath" v-model="analysis.sudden_death" :errors="validationErrors['analysis.sudden_death']" :form-submitted="submittedForm" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <h6 class="mt-2">{{ trans('palpitations') }}</h6>
                    <RadioButtonGroup name="palpitations" v-model="analysis.palpitations" :options="options" :errors="validationErrors['analysis.palpitations']" :form-submitted="submittedForm" />
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <h6 class="mt-2">{{ trans('uneven_heart_rate') }}</h6>
                    <RadioButtonGroup name="unevenHeartRate" v-model="analysis.uneven_heart_rate" :options="options" :errors="validationErrors['analysis.uneven_heart_rate']" :form-submitted="submittedForm" />
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <h6 class="mt-2">{{ trans('bruises') }}</h6>
                    <RadioButtonGroup name="bruises" v-model="analysis.bruises" :options="options" :errors="validationErrors['analysis.bruises']" :form-submitted="submittedForm" />
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <h6 class="mt-2">{{ trans('slow_pulse') }}</h6>
                    <RadioButtonGroup name="slowPulse" v-model="analysis.slow_pulse" :options="options" :errors="validationErrors['analysis.slow_pulse']" :form-submitted="submittedForm" />
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <h6 class="mt-2">{{ trans('fast_pulse') }}</h6>
                    <RadioButtonGroup name="fastPulse" v-model="analysis.fast_pulse" :options="options" :errors="validationErrors['analysis.fast_pulse']" :form-submitted="submittedForm" />
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <h6 class="mt-2">{{ trans('chest_pain_during_exertion') }}</h6>
                    <RadioButtonGroup name="chestPainExertion" v-model="analysis.chest_pain_exertion" :options="options" :errors="validationErrors['analysis.chest_pain_exertion']" :form-submitted="submittedForm" />
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <h6 class="mt-2">{{ trans('shortness_of_breath_on_exertion') }}</h6>
                    <RadioButtonGroup name="shortnessBreathExertion" v-model="analysis.shortness_breath_exertion" :options="options" :errors="validationErrors['analysis.shortness_breath_exertion']" :form-submitted="submittedForm" />
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <h6 class="mt-2">{{ trans('dizziness') }}</h6>
                    <RadioButtonGroup name="dizziness" v-model="analysis.dizziness" :options="options" :errors="validationErrors['analysis.dizziness']" :form-submitted="submittedForm" />
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <h6 class="mt-2">{{ trans('seizures_of_unconsciousness') }}</h6>
                    <RadioButtonGroup name="seizuresUnconsciousness" v-model="analysis.seizures_unconsciousness" :options="options" :errors="validationErrors['analysis.seizures_unconsciousness']" :form-submitted="submittedForm" />
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <h6 class="mt-2">{{ trans('blurring_of_consciousness') }}</h6>
                    <RadioButtonGroup name="blurringConsciousness" v-model="analysis.blurring_consciousness" :options="options" :errors="validationErrors['analysis.blurring_consciousness']" :form-submitted="submittedForm" />
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <h6 class="mt-2">{{ trans('do_you_feel_arrhythmias') }}</h6>
                    <RadioButtonGroup name="arrhythmias" v-model="analysis.arrhythmias" :options="arrhythmiasOptions" :errors="validationErrors['analysis.arrhythmias']" :form-submitted="submittedForm" />
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <TextArea :label="trans('main_symptom_occur_during_recording')" id="recordingMainSymptom" v-model="analysis.recording_main_symptom" :errors="validationErrors['analysis.recording_main_symptom']" :form-submitted="submittedForm" />
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <TextArea :label="trans('diary_entries')" id="diaryEntries" v-model="analysis.diary_entries" :errors="validationErrors['analysis.diary_entries']" :form-submitted="submittedForm" />
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <TextArea :label="trans('submission_text_research_topic_additional_information')" id="submissionText" v-model="analysis.submission_text" :errors="validationErrors['analysis.submission_text']" :form-submitted="submittedForm" />
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <TextArea :label="trans('comment')" id="comment" v-model="analysis.comment" :errors="validationErrors['analysis.comment']" :form-submitted="submittedForm" />
                </div>
            </div>
        </div>
        <button type="submit" class="btn btn-primary" :disabled="isStartedFileUpload">{{ trans('send') }}</button>
    </form> 
</template>

<script>
    import TextInput from '../common/inputs/TextInput.vue';
    import TextArea from '../common/inputs/TextArea.vue';
    import RadioButtonGroup from '../common/inputs/RadioButtonGroup.vue';
    import DropDownList from '../common/inputs/DropDownList.vue';
    import DatePickerInput from '../common/inputs/DatePickerInput.vue';
    import TimePickerInput from '../common/inputs/TimePickerInput.vue';
    import FileUpload from '../FileUpload.vue';
    import 'vue3-date-time-picker/dist/main.css';
    import { ref } from 'vue';

    export default {
        components: {
            TextInput,
            TextArea,
            RadioButtonGroup,
            DropDownList,
            DatePickerInput,
            TimePickerInput,
            FileUpload
        },
        data() {
            return {
                submittedForm: false,
                analysis: {
                    type: 'electrocardiogram',
                    recording_file: '',
                    recording_start_date: new Date(),
                    recording_start_time: ref({ hours: new Date().getHours(), minutes: new Date().getMinutes() }),
                    recording_duration: '',
                    gender: '',
                    birth_date: new Date('1970-01-01'),
                    length: '',
                    weight: '',
                    alcohol_usage: '',
                    smoking: '',
                    constant_medication: '',
                    is_research_day_medication: '',
                    research_day_medication: '',
                    is_diagnosed_heart_disease: '',
                    diagnosed_heart_disease: '',
                    is_sudden_death: '',
                    sudden_death: '',
					palpitations: '',
					uneven_heart_rate: '',
					bruises: '',
					slow_pulse: '',
					fast_pulse: '',
					chest_pain_exertion: '',
					shortness_breath_exertion: '',
					dizziness: '',
					seizures_unconsciousness: '',
					blurring_consciousness: '',
					arrhythmias: '',
					recording_main_symptom: '',
					diary_entries: '',
					submission_text: '',
					comment: ''
                },
                validationErrors: [],
                arrhythmiasOptions: [ 
                    { value: '0', text: this.trans('no_symptoms') },
                    { value: '1', text: this.trans('at_rest') },
                    { value: '2', text: this.trans('under_strain') },
                    { value: '3', text: this.trans('both') },
                ],
                durationOfRecordingOptions: [
                    { value: '1', text: '24 ' + this.trans('hours') },
                    { value: '2', text: '2 ' + this.trans('days') },
                    { value: '3', text: '3 ' + this.trans('days') },
                    { value: '4', text: '4 ' + this.trans('days') },
                    { value: '5', text: '5 ' + this.trans('days') },
                    { value: '6', text: '6 ' + this.trans('days') },
                    { value: '7', text: '7 ' + this.trans('days') }
                ],
                options: [
                    { value: '0', text: this.trans('no') },
                    { value: '1', text: this.trans('sometimes') },
                    { value: '2', text: this.trans('frequently') },
                ],
                genderOptions: [
                    { value: 'male', text: this.trans('male') },
                    { value: 'female', text: this.trans('female') }
                ],
                analysisTypeOptions: [
                    { value: 'electrocardiogram', text: this.trans('electrocardiogram'), disabled: false },
                    { value: 'sleep_apnea', text: this.trans('sleep_apnea'), disabled: true },
                    { value: 'blood_pressure', text: this.trans('blood_pressure'), disabled: true }
                ],
                researchDayMedicationOptions: [
                    { value: '0', text: this.trans('same_as_above') },
                    { value: '1', text: this.trans('other_what') }
                ],
                noYesWhatOptions: [
                    { value: '0', text: this.trans('no') },
                    { value: '1', text: this.trans('yes_what') }
                ],
                isStartedFileUpload: false,
                buttonText: this.trans('upload_recording_file'),
                maxFileSize: 1000000000,
                allowedFileTypes: ['application/pdf', '.edf'],
                maxNumberOfFiles: 1,
            }
        },
        methods: {
            trans(str) {
                return trans(str);
            },
            createAnalysis() {
                let request = postForm('/client/create-analysis', null, {analysis: this.analysis});
                let self = this;

                request.then(function (response) {
                    self.submittedForm = false;
                    self.resetFormData();
                    self.validationErrors = [];
                    window.location.href = '/client/new-analyses';
                });

                request.catch(function(error) {
                    self.submittedForm = true;
                    
                    if (error.response.status == 422) {
                       self.validationErrors = error.response.data.errors; 
                    }
                });
            },
            resetFormData() {
                this.submitForm = false;
                this.analysis.type = 'electrocardiogram';
                this.analysis.recording_file = '';
                this.analysis.recording_start_date = '';
                this.analysis.recording_start_time = '';
                this.analysis.recording_duration = '';
                this.analysis.gender = '';
                this.analysis.birth_date = '';
                this.analysis.length = '';
                this.analysis.weight = '';
                this.analysis.alcohol_usage = '';
                this.analysis.smoking = '';
                this.analysis.constant_medication = '';
                this.analysis.is_research_day_medication = '';
                this.analysis.research_day_medication = '';
                this.analysis.is_diagnosed_heart_disease = '';
                this.analysis.diagnosed_heart_disease = '';
                this.analysis.is_sudden_death = '';
                this.analysis.sudden_death = '';
                this.analysis.palpitations = '';
                this.analysis.uneven_heart_rate = '';
                this.analysis.bruises = '';
                this.analysis.slow_pulse = '';
                this.analysis.fast_pulse = '';
                this.analysis.chest_pain_exertion = '';
                this.analysis.shortness_breath_exertion = '';
                this.analysis.dizziness = '';
                this.analysis.seizures_unconsciousness = '';
                this.analysis.blurring_consciousness = '';
                this.analysis.arrhythmias = '';
                this.analysis.recording_main_symptom = '';
                this.analysis.diary_entries = '';
                this.analysis.submission_text = '';
                this.analysis.comment = '';
            },
            updateIsStartedFileUpload(val) {
                this.isStartedFileUpload = val;
            },
            updateFile(val) {
                this.analysis.recording_file = val;
            },
        },
    }
</script>
