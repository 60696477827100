<template>
    <label class="form-label">{{ label }}</label>
    <Datepicker :modelValue="vModelValue" @update:modelValue="vModelValue = $event" :inputClassName="formSubmitted ? (errors ? 'form-control is-invalid' : 'form-control is-valid') : ''" :clearable="false" autoApply timePicker></Datepicker>
    <div class="invalid-feedback" :class="[errors ? 'd-block' : '']">
        {{ errors ? errors[0] : '' }}
    </div>
</template>

<script>
    import Datepicker from 'vue3-date-time-picker';

    export default {
        emits: ['inputTimePicker'],
        props: {
            label: String,
            errors: Object,
            vModelValue: null,
            formSubmitted: {
                type: Boolean,
                default: false
            }
        },
        components: {
            Datepicker
        },
        watch: {
            vModelValue() {
                this.$emit('inputTimePicker', this.vModelValue);
            }
        }
    };
</script>
