<template>
    <ul class="nav main-menu">
        <li v-if="this.generalInfo.routeName == 'admin'" class="nav-item" :class="{ active: this.generalInfo.pageName == menu.name }" v-for="menu in adminMenus">
            <a class="nav-link text-reset" aria-current="page" :href="menu.link">{{ trans(menu.name) }}</a>
        </li>
        <li v-if="this.generalInfo.routeName == 'client'" class="nav-item" :class="{ active: this.generalInfo.pageName == menu.name }" v-for="menu in clientMenus">
            <a class="nav-link text-reset" aria-current="page" :href="menu.link">{{ trans(menu.name) }}</a>
        </li>
        <li v-if="this.generalInfo.routeName == 'service_provider'" class="nav-item" :class="{ active: this.generalInfo.pageName == menu.name }" v-for="menu in serviceProviderMenus">
            <a class="nav-link text-reset" aria-current="page" :href="menu.link">{{ trans(menu.name) }}</a>
        </li>
    </ul>
</template>

<script>
    export default {
        props: {
            generalInfo: {
                type: Object
            },
        },
        data: function() {
            return {

            }
        },
        computed: {
            adminMenus() {
                return [
                    { name: 'dashboard', link: '/admin/dashboard' },
                    { name: 'instances', link: '/admin/instances' },
                    { name: 'service_providers', link: '/admin/service-providers' },
                ];
            },
            clientMenus() {
                return [
                    { name: 'dashboard', link: '/client/dashboard' },
                    { name: 'create_new_analysis', link: '/client/create-analysis' },
                    { name: 'new_analyses', link: '/client/new-analyses' },
                    { name: 'analyses_process', link: '/client/analyses-process' },
                    { name: 'finished_analyses', link: '/client/finished-analyses'}
                ];
            },
            serviceProviderMenus() {
                return [
                    { name: 'dashboard', link: '/service-provider/dashboard' },
                    { name: 'new_analyses', link: '/service-provider/new-analyses' },
                    { name: 'analyses_process', link: '/service-provider/analyses-process' },
                    { name: 'finished_analyses', link: '/service-provider/finished-analyses'},
                    { name: 'organizations', link: '/service-provider/organizations'},
                    { name: 'users', link: '/service-provider/users'}
                ];
            }
        },
        methods: {
            trans(str) {
                return trans(str);
            }
        },
        mounted() {
        }
    }
</script>
