<template>
    <div class="modal fade" id="changeSubdomainModal" tabindex="-1" aria-labelledby="changeSubdomainModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="changeSubdomainModalLabel">{{ trans('change_subdomain') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form @submit.prevent="changeSubdomain">
                    <div class="modal-body">
                        <div class="mb-3">
                            <TextInput :label="trans('current_subdomain')" id="subdomain" :placeholder="subdomainPlaceholder" v-model="instance.subdomain" :errors="[]" :form-submitted="false" :disabled="true" />
                        </div>
                        <div class="mb-3">
                            <TextInput :label="trans('new_subdomain')" id="subdomain" :placeholder="subdomainPlaceholder" v-model="newSubdomain" :errors="validationErrors['subdomain']" :form-submitted="submittedForm" />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ trans('close') }}</button>
                        <button type="submit" class="btn btn-primary" :disabled="!newSubdomain || newSubdomain.trim() == instance.subdomain.trim()">{{ trans('save') }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import TextInput from '../common/inputs/TextInput.vue';

    export default {
        components: {
            TextInput,
        },
        props: {
            instanceData: Object,
        },
        data() {
            return {
                instance: this.instanceData,
                subdomainPlaceholder: this.trans('subdomain_placeholder'),
                newSubdomain: '',
                submittedForm: false,
                validationErrors: [],
            }
        },
        mounted() {
            let changeSubdomainModalEl = document.getElementById('changeSubdomainModal');
            let self = this;
            
            changeSubdomainModalEl.addEventListener('hidden.bs.modal', function (event) {
                self.resetForm();
                self.submittedForm = false;
                self.validationErrors = [];
            });
        },
        methods: {
            trans(str) {
                return trans(str);
            },
            changeSubdomain() {
                this.$confirm(
                    this.trans('change_subdomain_confirm'), 
                    '', 
                    '', 
                    {
                        title: '<h4 class="mb-0">'+ this.trans('change_subdomain') + ': '+ this.instance.subdomain +'</h4>',
                        buttonsStyling: false, 
                        customClass: {
                            confirmButton: 'btn btn-primary m-1', 
                            cancelButton: 'btn btn-secondary m-1'
                        }
                    }
                ).then(() => {
                    let request = postForm('/admin/change-subdomain', null, {id: this.instance.id, subdomain: this.newSubdomain});
                    let self = this;

                    request.then(function (response) {
                        toastMessage('success', response.message);
                        self.$emit('updateInstance', response.instanceData);
                        self.hideModal();
                        self.submittedForm = false;
                        self.validationErrors = [];
                    });

                    request.catch(function(error) {
                        self.submittedForm = true;
                        
                        if (error.response.status == 422) {
                            self.validationErrors = error.response.data.errors; 
                        }
                    });
                }).catch((error) => {
                    
                });
            },
            hideModal() {
                let changeSubdomainModalEl = document.getElementById('changeSubdomainModal');
                let changeSubdomainModal = bootstrap.Modal.getInstance(changeSubdomainModalEl);
                changeSubdomainModal.hide();
            },
            resetForm() {
                this.newSubdomain = '';
            }
        }
    }
</script>
