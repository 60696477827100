<template>
    <div class="row">
        <div class="col-md-6">
            <h3>{{ trans('organizations') }}</h3>
        </div>
        <div class="col-md-6 text-end">
            <a href="" class="btn btn-primary" @click.prevent="showCreateModal">{{ trans('create_organization') }}</a>
        </div>
    </div>
    <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">{{ trans('organization_name') }}</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="organizations.length > 0" v-for="(organization, index) in organizations">
                    <th scope="row">{{ (index + 1) }}</th>
                    <td>{{ organization.name }}</td>
                    <td><a href="" class="btn btn-sm btn-secondary" @click.prevent="showEditModal(organization.id, organization.name)">{{ trans('edit') }}</a></td>
                    <td><a href="" class="btn btn-sm btn-danger" @click.prevent="deleteOrganization(organization.id, organization.name)">{{ trans('delete') }}</a></td>
                </tr>
                <tr v-else>
                    <td colspan="4" class="text-center">{{ trans('no_data_found') }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <pagination v-if="totalPages > 0" @pageChanged="onChangePage" :total-pages="totalPages" :per-page="perPage" :current-page="currentPage" />
    <organization-form @updateOrganizationData="changeOrganizationData" @resetName="changeName" :modal-type="modalType" :modal-title="modalTitle" :organization-id="organizationId" :organization-name="organizationName" :url="url" />
</template>

<script>
    import OrganizationForm from './OrganizationForm.vue';
    import {Modal} from 'bootstrap';
    import Pagination from '../Pagination.vue';

    export default {
        components: {
            OrganizationForm,
            Pagination
        },
        props: {
            organizationsData: {
                type: Object
            },
            totalRows: {
                type: Number
            },
        },
        data() {
            return {
                organizations: this.organizationsData,
                totalNumRows: this.totalRows,
                modalType: 'create',
                modalTitle: this.trans('create_organization'),
                organizationId: null,
                organizationName: '',
                url: 'create-organization',
                perPage: 10,
                currentPage: 1,
            }
        },
        computed: {
            totalPages() {
                return Math.ceil(this.totalNumRows / this.perPage);
            }
        },
        methods: {
            trans(str) {
                return trans(str);
            },
            showCreateModal() {
                let createModal = new Modal(document.getElementById('organizationModal'));
                createModal.show();
                this.modalType = 'create';
                this.modalTitle = this.trans('create_organization');
                this.organizationName = '';
                this.url = 'create-organization';
            },
            showEditModal(id, name) {
                let editModal = new Modal(document.getElementById('organizationModal'));
                editModal.show();
                this.modalType = 'edit';
                this.modalTitle = this.trans('edit_organization');
                this.organizationId = parseInt(id);
                this.organizationName = name;
                this.url = 'edit-organization';
            },
            changeOrganizationData(val) {
                if (this.modalType == 'create') {
                    let pageOffset = this.totalPages > 0 ? this.totalPages : 1;
                    this.onChangePage(pageOffset);
                } else {
                    for (let org in this.organizations) {
                        if (this.organizations[org].id == val.id) {
                            this.organizations[org].name = val.name;
                        }
                    }
                }
            },
            changeName(val) {
                this.organizationName = val;
            },
            deleteOrganization(id, name) {
                this.$confirm(
                    this.trans('delete_organization_confirm'), 
                    '', 
                    '', 
                    {
                        title: '<h4 class="mb-0">'+ this.trans('delete_organization') +': '+ name +'</h4>',
                        buttonsStyling: false, 
                        customClass: {
                            confirmButton: 'btn btn-primary m-1', 
                            cancelButton: 'btn btn-secondary m-1'
                        }
                    }
                ).then(() => {
                    let request = post('/service-provider/delete-organization', {id: id});
                    let self = this;

                    request.then(function (response) {
                        for (let org in self.organizations) {
                            if (self.organizations[org].id == response.data.id) {
                                self.organizations.splice(org, 1);
                            }
                        }

                        if (self.organizations.length == 0) {
                            self.totalNumRows = 0;
                        }

                        toastMessage('success', response.data.message);
                    });
                }).catch((error) => {
                    
                });
            },
            onChangePage(page) {
                this.currentPage = page;
                let offset = this.currentPage - 1;

                let request = get('/service-provider/organizations?offset='+ offset);
                let self = this;

                request.then(function (response) {
                    self.organizations = response.organizationsData;
                    self.totalNumRows = response.totalRows;
                });
            }
        },
    }
</script>
