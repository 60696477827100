<template>
    <h3>{{ trans('new_analyses') }}</h3>
    <div v-if="Object.keys(analyses).length > 0" class="p-3 mb-2 bg-light text-dark" v-for="(analyse, index) in analyses">
        <div class="row">
            <div class="col-md-3">
                <p class="mb-1">{{ trans('analysis_type') }}: {{ trans(analyse.data.type) }}</p>
                <p class="mb-1">{{ trans('created_at') }}: {{ analyse.created_at }}</p>
            </div>
            <div class="col-md-3">
                <p class="mb-1">{{ trans('gender') }}: {{ trans(analyse.data.gender) }}</p>
                <p class="mb-1">{{ trans('date_of_birth') }}: {{ formatDate(analyse.data.birth_date) }}</p>
            </div>
            <div class="col-md-3">
                <p class="mb-1">{{ trans('weight') }}: {{ analyse.data.weight }}</p>
                <p class="mb-1">{{ trans('length') }}: {{ analyse.data.length }}</p>
            </div>
            <div class="col-md-3">
                <div class="form-check form-switch" v-if="generalInfo.routeName == 'service_provider'">
                    <input class="form-check-input" type="checkbox" id="analysisStatusChecked" v-model="analyse.status" @change="changeStatusToInprogress(analyse.id, analyse.status)">
                    <label class="form-check-label" for="analysisStatusChecked">{{ convetStatusToString(analyse.status) }}</label>
                </div>
                <div v-else>
                    <p class="mb-1">{{ trans('status') }}: {{ convetStatusToString(analyse.status) }}</p>
                </div>
                <a href="" @click.prevent="viewAnalysis(analyse.id)">{{ trans('view_details') }}</a>
            </div>
        </div>
    </div>
    <div v-else class="p-3 mb-2 bg-light text-dark text-center">
        <p class="mb-0">{{ trans('no_data_found') }}</p>
    </div>
    <pagination v-if="totalPages > 0" @pageChanged="onChangePage" :total-pages="totalPages" :per-page="perPage" :current-page="currentPage" />
    <new-analyse-view :new-analyse="analyse" />
</template>

<script>
    import NewAnalyseView from './NewAnalyseView.vue';
    import {Modal} from 'bootstrap';
    import Pagination from '../Pagination.vue';

    export default {
        components: {
            NewAnalyseView,
            Pagination
        },
        props: {
            generalInfo: {
                type: Object
            },
            analysesData: {
                type: Object
            },
            totalRows: {
                type: Number
            }
        },
        data() {
            return {
                analyses: this.analysesData,
                totalNumRows: this.totalRows,
                analyse: {
                    id: null,
                    data: {
                        type: 'electrocardiogram',
                        recording_start_date: '',
                        recording_start_time: '',
                        recording_duration: '',
                        gender: '',
                        birth_date: '',
                        length: '',
                        weight: '',
                        alcohol_usage: '',
                        smoking: '',
                        constant_medication: '',
                        is_research_day_medication: '',
                        research_day_medication: '',
                        is_diagnosed_heart_disease: '',
                        diagnosed_heart_disease: '',
                        is_sudden_death: '',
                        sudden_death: '',
                        palpitations: '',
                        uneven_heart_rate: '',
                        bruises: '',
                        slow_pulse: '',
                        fast_pulse: '',
                        chest_pain_exertion: '',
                        shortness_breath_exertion: '',
                        dizziness: '',
                        seizures_unconsciousness: '',
                        blurring_consciousness: '',
                        arrhythmias: '',
                        recording_main_symptom: '',
                        diary_entries: '',
                        submission_text: '',
                        comment: ''
                    },
                    recording_file_id: null,
                    recording_file_name: '',
                    status: 0
                },
                perPage: 10,
                currentPage: 1,
            }
        },
        computed: {
            totalPages() {
                return Math.ceil(this.totalNumRows / this.perPage);
            }
        },
        methods: {
            trans(str) {
                return trans(str);
            },
            formatDate(date) {
                return formatDate(date);
            },
            convetStatusToString(status) {
                let str = this.trans('created');

                if (status == 0) {
                    str = this.trans('created');
                } else if (status == 1) {
                    str = this.trans('in_progress');
                }

                return str;
            },
            viewAnalysis(id)
            {
                this.analyse = this.analyses[id];
                let viewModal = new Modal(document.getElementById('viewAnalysisModal'));
                viewModal.show();
            },
            changeStatusToInprogress(id, status) {
                if (status) {
                    this.$confirm(
                        this.trans('change_wip_status_confirm'), 
                        '', 
                        '', 
                        {
                            title: '<h3 class="mb-0">'+ this.trans('change_status') +'</h3>',
                            buttonsStyling: false, 
                            customClass: {
                                confirmButton: 'btn btn-primary m-1', 
                                cancelButton: 'btn btn-secondary m-1'
                            }
                        }
                    ).then(() => {
                        let request = post('/service-provider/change-status', {id: id, status: 2});
                        let self = this;

                        request.then(function (response) {
                            
                            for (let id in self.analyses) {
                                if (self.analyses[id].id == response.data.id) {
                                    delete self.analyses[id];
                                }
                            }

                            if (Object.keys(self.analyses).length == 0) {
                                self.totalNumRows = 0;
                            }

                            toastMessage('success', response.data.message);
                        });
                    }).catch((error) => {
                        this.analyses[id].status = false;
                    });
                }
            },
            onChangePage(page) {
                this.currentPage = page;
                let offset = this.currentPage - 1;
                let url = '';

                if (this.generalInfo.routeName == 'client') {
                    url = '/client/new-analyses?offset='+ offset;
                }

                if (this.generalInfo.routeName == 'service_provider') {
                    url = '/service-provider/new-analyses?offset='+ offset;
                }

                let request = get(url);
                let self = this;

                request.then(function (response) {
                    self.analyses = response.analysesData;
                    self.totalNumRows = response.totalRows;
                });
            }
        }
    }
</script>
