<template>
    <div class="modal fade" id="userModal" tabindex="-1" aria-labelledby="userModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="userModalLabel">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form @submit.prevent="saveUser">
                    <input type="hidden" name="id" v-model="orgUser.id">
                    <div class="modal-body">
                        <div class="mb-3">
                            <drop-down-list :label="trans('organization_name')" id="organizationName" v-model="orgUser.organization" :options="organizationList" :errors="validationErrors['organization']" :form-submitted="submittedForm" />
                        </div>
                        <div class="mb-3">
                            <text-input :label="trans('fullname')" id="name" v-model="orgUser.name" :errors="validationErrors['name']" :form-submitted="submittedForm" />
                        </div>
                        <div class="mb-3">
                            <email-input :label="trans('email_address')" id="email" v-model="orgUser.email" :errors="validationErrors['email']" :form-submitted="submittedForm" />
                        </div>
                        <div class="mb-3">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ trans('close') }}</button>
                        <button type="submit" class="btn btn-primary" :disabled="!orgUser.name || !orgUser.organization || !orgUser.email">{{ trans('save') }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import TextInput from '../common/inputs/TextInput.vue';
    import EmailInput from '../common/inputs/EmailInput.vue';
    import DropDownList from '../common/inputs/DropDownList.vue';

    export default {
        components: {
            TextInput,
            EmailInput,
            DropDownList
        },
        props: {
            modalType: {
                type: String
            },
            modalTitle: {
                type: String
            },
            url: {
                type: String
            },
            organizations: {
                type: Object
            },
            user: {
                type: Object
            }
        },
        data() {
            return {
               organizationList: this.organizations,
               orgUser: this.user,
               submittedForm: false,
               validationErrors: [],
            }
        },
        mounted() {
            let userModalEl = document.getElementById('userModal');
            let self = this;
            
            userModalEl.addEventListener('hidden.bs.modal', function (event) {
                self.resetForm();
                self.submittedForm = false;
                self.validationErrors = [];
            });
        },
        methods: {
            trans(str) {
                return trans(str);
            },
            saveUser() {
                let request = postForm(this.url, null, {id: this.orgUser.id, organization: this.orgUser.organization, name: this.orgUser.name, email: this.orgUser.email});
                let self = this;

                request.then(function (response) {
                    toastMessage('success', response.message);
                    self.$emit('updateUserData', response.userData);
                    self.hideModal();
                    self.submittedForm = false;
                    self.validationErrors = [];
                });

                request.catch(function(error) {
                    self.submittedForm = true;
                    
                    if (error.response.status == 422) {
                       self.validationErrors = error.response.data.errors; 
                    }
                });
            },
            hideModal() {
                let userModalEl = document.getElementById('userModal');
                let userModal = bootstrap.Modal.getInstance(userModalEl);
                userModal.hide();
            },
            resetForm() {
                this.orgUser.organization = '';
                this.orgUser.name = '';
                this.orgUser.email = '';
            }
        },
    }
</script>
