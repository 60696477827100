<template>
    <div class="modal fade" id="organizationModal" tabindex="-1" aria-labelledby="organizationModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="organizationModalLabel">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form @submit.prevent="saveOrganization">
                    <div class="modal-body">
                        <div class="mb-3">
                            <text-input :label="trans('organization_name')" id="organizationName" :modelValue="organizationName" @update:modelValue="organizationName = $event" :errors="validationErrors['name']" :form-submitted="submittedForm" />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ trans('close') }}</button>
                        <button type="submit" class="btn btn-primary" :disabled="!organizationName.trim()">{{ trans('save') }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import TextInput from '../common/inputs/TextInput.vue';

    export default {
        components: {
            TextInput
        },
        props: {
            modalType: {
                type: String
            },
            modalTitle: {
                type: String
            },
            organizationId: {
                type: Number
            },
            organizationName: {
                type: String
            },
            url: {
                type: String
            }
        },
        data() {
            return {
                submittedForm: false,
                validationErrors: [],
            }
        },
        mounted() {
            let organizationModalEl = document.getElementById('organizationModal');
            let self = this;
            
            organizationModalEl.addEventListener('hidden.bs.modal', function (event) {
                if (self.modalType == 'create') {
                    self.$emit('resetName', self.organizationName);
                }

                self.validationErrors = [];
                self.submittedForm = false;
            });
        },
        methods: {
            trans(str) {
                return trans(str);
            },
            saveOrganization() {
                let request = postForm(this.url, null, {id: this.organizationId, name: this.organizationName});
                let self = this;

                request.then(function (response) {
                    toastMessage('success', response.message);
                    self.$emit('updateOrganizationData', response.organizationData);
                    self.hideModal();
                    self.submittedForm = false;
                    self.validationErrors = [];
                });

                request.catch(function(error) {
                    self.submittedForm = true;
                    
                    if (error.response.status == 422) {
                       self.validationErrors = error.response.data.errors; 
                    }
                });
            },
            hideModal() {
                let organizationModalEl = document.getElementById('organizationModal');
                let organizationModal = bootstrap.Modal.getInstance(organizationModalEl);
                organizationModal.hide();
            }
        },
    }
</script>
