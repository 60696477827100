<template>
    <div v-if="activePage == 'list'">
        <h3>{{ trans('finished_analyses') }}</h3>
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">{{ trans('analysis_type') }}</th>
                        <th scope="col">{{ trans('status') }}</th>
                        <th scope="col">{{ trans('created_by') }}</th>
                        <th scope="col">{{ trans('created_at') }}</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="Object.keys(analyses).length > 0" v-for="(value, name, index) in analyses">
                        <th scope="row">{{ (index + 1) }}</th>
                        <td>{{ trans(value.type) }}</td>
                        <td>{{ statusName(value.status) }}</td>
                        <td>{{ value.created_by }}</td>
                        <td>{{ value.created_at }}</td>
                        <td><a href="" @click.prevent="showDetails(value)">{{ trans('view_details') }}</a></td>  
                    </tr>
                    <tr v-else>
                        <td colspan="6" class="text-center">{{ trans('no_data_found') }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <pagination v-if="totalPages > 0" @pageChanged="onChangePage" :total-pages="totalPages" :per-page="perPage" :current-page="currentPage" />
    </div>
    <analyse-view-details v-if="activePage == 'view_details'" @updateActivePage="changeActivePage" :general-info="generalInfo" :analyse-data="analyse" />
</template>

<script>
    import AnalyseViewDetails from './AnalyseViewDetails.vue';
    import Pagination from '../Pagination.vue';

    export default {
        components: {
            AnalyseViewDetails,
            Pagination
        },
        props: {
            generalInfo: {
                type: Object
            },
            analysesData: {
                type: Object
            },
            totalRows: {
                type: Number
            }
        },
        data() {
            return {
                analyses: this.analysesData,
                totalNumRows: this.totalRows,
                activePage: 'list',
                analyse: {
                    id: null,
                    data: {
                        type: 'electrocardiogram',
                        recording_start_date: '',
                        recording_start_time: '',
                        recording_duration: '',
                        gender: '',
                        birth_date: '',
                        length: '',
                        weight: '',
                        alcohol_usage: '',
                        smoking: '',
                        constant_medication: '',
                        is_research_day_medication: '',
                        research_day_medication: '',
                        is_diagnosed_heart_disease: '',
                        diagnosed_heart_disease: '',
                        is_sudden_death: '',
                        sudden_death: '',
                        palpitations: '',
                        uneven_heart_rate: '',
                        bruises: '',
                        slow_pulse: '',
                        fast_pulse: '',
                        chest_pain_exertion: '',
                        shortness_breath_exertion: '',
                        dizziness: '',
                        seizures_unconsciousness: '',
                        blurring_consciousness: '',
                        arrhythmias: '',
                        recording_main_symptom: '',
                        diary_entries: '',
                        submission_text: '',
                        comment: ''
                    },
                    recording_file_id: null,
                    recording_file_name: '',
                    analysis_file_id: null,
                    analysis_file_name: '',
                    status: 0,
                    comment: ''
                },
                perPage: 10,
                currentPage: 1,
            }
        },
        computed: {
            totalPages() {
                return Math.ceil(this.totalNumRows / this.perPage);
            }
        },
        mounted() {
            this.activePage = "list";
        },
        methods: {
            trans(str) {
                return trans(str);
            },
            statusName(status) {
                let str = '';

                if (status == 1) {
                    str = this.trans('finished');
                }

                return str;
            },
            showDetails(analyse) {
                this.activePage = "view_details";
                this.analyse = analyse;
            },
            changeActivePage(val) {
                this.activePage = val;
            },
            onChangePage(page) {
                this.currentPage = page;
                let offset = this.currentPage - 1;
                let url = '';

                if (this.generalInfo.routeName == 'client') {
                    url = '/client/finished-analyses?offset='+ offset;
                }

                if (this.generalInfo.routeName == 'service_provider') {
                    url = '/service-provider/finished-analyses?offset='+ offset;
                }

                let request = get(url);
                let self = this;

                request.then(function (response) {
                    self.analyses = response.analysesData;
                    self.totalNumRows = response.totalRows;
                });
            }
        }
    }
</script>
