<template>
    <div class="row">
        <div class="col-md-6">
            <h3>{{ trans('instances') }}</h3>
        </div>
        <div class="col-md-6 text-end">
            <a href="/admin/create-instance" class="btn btn-primary">{{ trans('create_instance') }}</a>
        </div>
    </div>
    <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">{{ trans('name') }}</th>
                    <th scope="col">{{ trans('subdomain') }}</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="instances.length > 0" v-for="(instance, index) in instances">
                    <th scope="row">{{ (index + 1) }}</th>
                    <td>{{ instance.name }}</td>
                    <td>{{ instance.subdomain }}</td>
                    <td><a href="#" class="btn btn-sm btn-info" @click.prevent="showChangeSubdomainModal(instance)">{{ trans('change_subdomain') }}</a></td>
                    <td><a :href="'/admin/edit-instance/'+ instance.id" class="btn btn-sm btn-secondary">{{ trans('edit') }}</a></td>
                    <td><a href="#" class="btn btn-sm btn-danger" @click.prevent="deleteInstance(instance.id, instance.name)">{{ trans('delete') }}</a></td>
                </tr>
                <tr v-else>
                    <td colspan="5" class="text-center">{{ trans('no_data_found') }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <pagination v-if="totalPages > 0" @pageChanged="onChangePage" :total-pages="totalPages" :per-page="perPage" :current-page="currentPage" />
    <change-subdomain @updateInstance="listenUpdateInstance" :instance-data="instanceData" />
</template>

<script>
    import Pagination from '../Pagination.vue';
    import { Modal } from 'bootstrap';
    import ChangeSubdomain from './ChangeSubdomain.vue';

    export default {
        components: {
            Pagination,
            ChangeSubdomain
        },
        props: {
            instancesData: {
                type: Object
            },
            totalRows: {
                type: Number
            },
        },
        data() {
            return {
                instances: this.instancesData,
                totalNumRows: this.totalRows,
                perPage: 10,
                currentPage: 1,
                instanceData: {
                    id: null,
                    subdomain: ''
                }
            }
        },
        computed: {
            totalPages() {
                return Math.ceil(this.totalNumRows / this.perPage);
            }
        },
        methods: {
            trans(str) {
                return trans(str);
            },
            onChangePage(page) {
                this.currentPage = page;
                let offset = this.currentPage - 1;

                let request = get('/admin/instances?offset='+ offset);
                let self = this;

                request.then(function (response) {
                    self.instances = response.instancesData;
                    self.totalNumRows = response.totalRows;
                });
            },
            deleteInstance(id, name) {
                this.$prompt(
                    this.trans('delete_instance_confirm'), 
                    '', 
                    '',
                    '',
                    {
                        title: '<h4 class="mb-0">'+ this.trans('delete_instance') +': '+ name +'</h4>',
                        buttonsStyling: false, 
                        customClass: {
                            confirmButton: 'btn btn-primary m-1', 
                            cancelButton: 'btn btn-secondary m-1',
                            input: 'form-control',
                        }
                    }
                ).then((text) => {
                    if (text.trim() == 'delete') {
                        let request = post('/admin/delete-instance', {id: id});
                        let self = this;

                        request.then(function (response) {
                            for (let ins in self.instances) {
                                if (self.instances[ins].id == response.data.id) {
                                    self.instances.splice(ins, 1);
                                }
                            }

                            toastMessage('success', response.data.message);
                        });
                    }
                }).catch((error) => {
                    
                });
            },
            showChangeSubdomainModal(instance) {
                let changeSubDomainModal = new Modal(document.getElementById('changeSubdomainModal'));
                changeSubDomainModal.show();
                this.instanceData.id = instance.id;
                this.instanceData.subdomain = instance.subdomain;
            },
            listenUpdateInstance(val) {
                for (let ins in this.instances) {
                    if (this.instances[ins].id == val.id) {
                        this.instances[ins].subdomain = val.subdomain;
                    }
                }
            }
        }
    }
</script>
<style>
    .swal2-input {
        height: auto !important;
    }
</style>
