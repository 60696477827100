<template>
    <div class="form-check form-check-inline" v-for="(option, index) in options">
        <input 
          class="form-check-input" 
          type="radio"
          :name="name" 
          :id="`${name}${option.value}`" 
          :class="[formSubmitted ? (errors ? 'is-invalid' : 'is-valid') : '', this.class]" 
          :checked="option.value == modelValue" 
          :disabled="option.disabled" 
          :value="option.value" 
          @change="$emit('update:modelValue', $event.target.value)"
        >
        <label class="form-check-label" :for="`${name}${option.value}`">{{ option.text }}</label>
    </div>
    <div class="invalid-feedback" :class="[errors ? 'd-block' : '']">
        {{ errors ? errors[0] : '' }}
    </div>
</template>

<script>
export default {
  props: {
    name: { 
      type: String,
      required: true,
    },
    class: String,
    modelValue: String,
    options: {
        required: true,
        type: Object
    },
    errors: Object,
    formSubmitted: {
        type: Boolean,
        default: false
    }
  },
};
</script>
