<template>
    <div class="modal fade" id="viewAnalysisModal" tabindex="-1" aria-labelledby="viewAnalysisModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="viewAnalysisModalLabel">{{ trans('analysis_details') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <p class="mb-0"><span>{{ trans('analysis_type') }}:</span> {{ trans(newAnalyse.data.type) }}</p>
                        </div>
                        <div class="col-md-6">
                            <h5 class="my-2">{{ trans('recording') }}</h5>
                            <p class="mb-0"><span>{{ trans('recording_file') }}:</span> <a href="" @click.prevent="downloadFile($event,`/file/${newAnalyse.recording_file_id}/downloadUrl`, newAnalyse.recording_file_name)">{{ newAnalyse.recording_file_name }}</a></p>
                            <p class="mb-0"><span>{{ trans('recording_start_date') }}:</span> {{ formatDate(newAnalyse.data.recording_start_date) }}</p>
                            <p class="mb-0"><span>{{ trans('recording_start_time') }}:</span> {{ newAnalyse.data.recording_start_time.hours }}:{{ newAnalyse.data.recording_start_time.minutes }}</p>
                            <p class="mb-0"><span>{{ trans('duration_of_recording') }}:</span> {{ newAnalyse.data.recording_duration == 1 ? 24 : newAnalyse.data.recording_duration }} {{ newAnalyse.data.recording_duration == 1 ? trans('hours') : trans('days') }}</p>
                            <h5 class="my-2">{{ trans('basic_information') }}</h5>
                            <p class="mb-0"><span>{{ trans('gender') }}:</span> {{ trans(newAnalyse.data.gender) }}</p>
                            <p class="mb-0"><span>{{ trans('date_of_birth') }}:</span> {{ formatDate(newAnalyse.data.birth_date) }}</p>
                            <p class="mb-0"><span>{{ trans('length') }}:</span> {{ newAnalyse.data.length }}</p>
                            <p class="mb-0"><span>{{ trans('weight') }}:</span> {{ newAnalyse.data.weight }}</p>
                            <h5 class="my-2">{{ trans('lifestyles') }}</h5>
                            <p class="mb-0"><span>{{ trans('usage_of_alcohol') }}:</span> {{ convertIntToStringOne(newAnalyse.data.alcohol_usage) }}</p>
                            <p class="mb-0"><span>{{ trans('smoking') }}:</span> {{ convertIntToStringOne(newAnalyse.data.smoking) }}</p>
                            <h5 class="my-2">{{ trans('medication') }}</h5>
                            <p class="mb-0"><span>{{ trans('constant_medication') }}:</span> {{ newAnalyse.data.constant_medication }}</p>
                            <p class="mb-0"><span>{{ trans('research_day_medication') }}:</span> {{ newAnalyse.data.is_research_day_medication == 0 ? trans('same_as_above') :  newAnalyse.data.is_research_day_medication}}</p>
                        </div>
                        <div class="col-md-6">
                            <h5>{{ trans('heart_health') }}</h5>
                            <p class="mb-0"><span>{{ trans('diagnosed_heart_disease') }}:</span> {{ newAnalyse.data.is_diagnosed_heart_disease == 0 ? trans('no') :  newAnalyse.data.diagnosed_heart_disease}}</p>
                            <p class="mb-0"><span>{{ trans('sudden_death_arrhythmias_myocardial_infarction') }}:</span> {{ newAnalyse.data.is_sudden_death == 0 ? trans('no') :  newAnalyse.data.sudden_death}}</p>
                            <p class="mb-0"><span>{{ trans('palpitations') }}:</span> {{ convertIntToStringOne(newAnalyse.data.palpitations) }}</p>
                            <p class="mb-0"><span>{{ trans('uneven_heart_rate') }}:</span> {{ convertIntToStringOne(newAnalyse.data.uneven_heart_rate) }}</p>
                            <p class="mb-0"><span>{{ trans('bruises') }}:</span> {{ convertIntToStringOne(newAnalyse.data.bruises) }}</p>
                            <p class="mb-0"><span>{{ trans('slow_pulse') }}:</span> {{ convertIntToStringOne(newAnalyse.data.slow_pulse) }}</p>
                            <p class="mb-0"><span>{{ trans('fast_pulse') }}:</span> {{ convertIntToStringOne(newAnalyse.data.fast_pulse) }}</p>
                            <p class="mb-0"><span>{{ trans('chest_pain_during_exertion') }}:</span> {{ convertIntToStringOne(newAnalyse.data.chest_pain_exertion) }}</p>
                            <p class="mb-0"><span>{{ trans('shortness_of_breath_on_exertion') }}:</span> {{ convertIntToStringOne(newAnalyse.data.shortness_breath_exertion) }}</p>
                            <p class="mb-0"><span>{{ trans('dizziness') }}:</span> {{ convertIntToStringOne(newAnalyse.data.dizziness) }}</p>
                            <p class="mb-0"><span>{{ trans('seizures_of_unconsciousness') }}:</span> {{ convertIntToStringOne(newAnalyse.data.seizures_unconsciousness) }}</p>
                            <p class="mb-0"><span>{{ trans('blurring_of_consciousness') }}:</span> {{ convertIntToStringOne(newAnalyse.data.blurring_consciousness) }}</p>
                            <p class="mb-0"><span>{{ trans('do_you_feel_arrhythmias') }}:</span> {{ convertIntToStringTwo(newAnalyse.data.arrhythmias) }}</p>
                        </div>
                        <div class="col-md-12 mt-2">
                            <p class="mb-0"><span>{{ trans('main_symptom_occur_during_recording') }}:</span> {{ newAnalyse.data.recording_main_symptom }}</p>
                            <p class="mb-0"><span>{{ trans('diary_entries') }}:</span> {{ newAnalyse.data.diary_entries }}</p>
                            <p class="mb-0"><span>{{ trans('submission_text_research_topic_additional_information') }}:</span> {{ newAnalyse.data.submission_text }}</p>
                            <p class="mb-0"><span>{{ trans('comment') }}:</span> {{ newAnalyse.data.comment }}</p>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ trans('close') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            newAnalyse: {
                type: Object
            }
        },
        methods: {
            trans(str) {
                return trans(str);
            },
            downloadFile(event, fileUrl, fileName) {
                event.preventDefault();
                let request = get(fileUrl);
                request.then(response => {
                    const link = document.createElement('a');
                    link.href = response;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
            },
            formatDate(date) {
                return formatDate(date);
            },
            convertIntToStringOne(val) {
                let str = this.trans('no');

                if (val == 0) {
                    str = this.trans('no');
                } else if(val == 1) {
                    str = this.trans('sometimes');
                } else if(val == 2) {
                    str = this.trans('frequently');
                }

                return str;
            },
            convertIntToStringTwo(val) {
                let str = this.trans('no_symptoms');

                if (val == 0) {
                    str = this.trans('no_symptoms');
                } else if(val == 1) {
                    str = this.trans('at_rest');
                } else if(val == 2) {
                    str = this.trans('under_strain');
                } else if(val == 3) {
                    str = this.trans('both');
                }

                return str;
            }
        }
    }
</script>
