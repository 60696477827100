<template>
    <label :for="id" class="form-label">{{ label }}</label>
    <select 
      class="form-select" 
      :id="id" 
      :class="[formSubmitted ? (errors ? 'is-invalid' : 'is-valid') : '', this.class]" 
      :value="modelValue" 
      @change="$emit('update:modelValue', $event.target.value)"
    >
        <option value='' selected>{{ trans('select_an_option') }}</option>
        <option :value="option.value" v-for="(option, index) in options">{{ option.text }}</option>
    </select>
    <div class="invalid-feedback" :class="[errors ? 'd-block' : '']">
        {{ errors ? errors[0] : '' }}
    </div>
</template>

<script>
export default {
  props: {
    id: String,
    class: String,
    label: String,
    modelValue: String,
    options: {
        required: true,
        type: Object
    },
    errors: Object,
    formSubmitted: {
        type: Boolean,
        default: false
    }
  },
  methods: {
      trans(str) {
          return trans(str);
      },
  }
};
</script>
