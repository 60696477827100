<template>
    <form @submit.prevent="saveProfile">
        <div class="mb-3">
            <text-input :label="trans('name')" id="name" v-model="userData.name" :errors="validationErrors['name']" :form-submitted="submittedForm" />  
        </div>
        <div class="mb-3">
            <email-input :label="trans('email_address')" id="email" v-model="userData.email" :disabled="isDisabled" />
        </div>
        <div class="mb-3">
            <text-input :label="trans('role')" id="email" v-model="userData.role" :disabled="isDisabled" />
        </div>
        <div v-if="userData.organization" class="mb-3">
            <text-input :label="trans('organization')" id="email" v-model="userData.organization" :disabled="isDisabled" />
        </div>
        <a :href="userData.redirect" class="btn btn-secondary me-3">{{ trans('cancel') }}</a>
        <button type="submit" class="btn btn-primary">{{ trans('save') }}</button>
    </form>
</template>

<script>
    import TextInput from '../common/inputs/TextInput.vue';
    import EmailInput from '../common/inputs/EmailInput.vue';

    export default {
        components: {
            TextInput,
            EmailInput,
        },
        props: {
            userData: Object
        },
        data() {
            return {
                submittedForm: false,
                validationErrors: [],
                isDisabled: true,
            }
        },
        methods: {
            trans(str) {
                return trans(str);
            },
            saveProfile() {
                let request = postForm('/profile/save', null, {name: this.userData.name});
                let self = this;

                request.then(function (response) {
                    self.submittedForm = false;
                    self.validationErrors = [];
                    window.location.href = self.userData.redirect;
                });

                request.catch(function(error) {
                    self.submittedForm = true;
                    
                    if (error.response.status == 422) {
                       self.validationErrors = error.response.data.errors; 
                    }
                });
            }
        }
    }
</script>
