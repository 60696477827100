<template>
    <div class="row">
        <div class="col-md-12">
            <h3>{{ id ? trans('edit_instance') : trans('create_instance') }}</h3>
        </div>
        <div class="col-md-6">
            <div class="mb-3">
                <TextInput :label="trans('name')" id="name" :placeholder="namePlaceholder" v-model="name" :errors="validationErrors['name']" :form-submitted="submittedForm" />
            </div>
        </div>
        <div class="col-md-6">
            <div class="mb-3">
                <TextInput :label="trans('subdomain')" id="subdomain" :placeholder="subdomainPlaceholder" v-model="subdomain" :errors="id ? [] : validationErrors['subdomain']" :form-submitted="id ? false : submittedForm" :disabled="id ? true : false" />
            </div>
        </div>
        <div class="col-md-12">
            <label class="form-label">{{ trans('logo') }}</label>
            <input type="hidden" v-model="logo">
        </div>
        <div class="col-md-12 mb-3">
            <file-upload @updateIsStartedFileUpload="updateIsStartedFileUpload" @updateFile="updateFile" buttonClasses="btn btn-info" :buttonText="buttonText" :maxFileSize="this.maxFileSize" :allowedFileTypes="this.allowedFileTypes" :maxNumberOfFiles="this.maxNumberOfFiles" uploadUrl="/admin/upload-instance-logo" deleteUrl="/admin/delete-instance-logo" :existingUploadedFiles="id && instanceData.logoId ? existingUploadedFiles : []" :uploadedSuccessResponse="id && instanceData.logoId ? uploadedSuccessResponse : {}" isStartedFileUpload isUploadInForm />
        </div>
        <div class="col-md-12 mb-3">
            <label class="form-label" for="style">{{ trans('style') }}</label>
            <CodeEditor theme="light" :wrap_code="true" width="100%" min_height="200px" :language_selector="true" :languages="[['css', 'CSS']]" v-model="styles"></CodeEditor>
        </div>
        <div class="col-md-12">
            <a href="/admin/instances" class="btn btn-secondary me-3">{{ trans('back') }}</a>
            <button type="button" class="btn btn-primary me-3" :disabled="!name || !subdomain" @click.prevent="saveInstance">{{ trans('save') }}</button>
        </div>
    </div>
</template>

<script>
    import TextInput from '../common/inputs/TextInput.vue';
    import FileUpload from '../FileUpload.vue';
    import CodeEditor from 'simple-code-editor';

    export default {
        props: {
            instanceData: Object,
        },
        components: {
            TextInput,
            FileUpload,
            CodeEditor
        },
        data() {
            return {
                submittedForm: false,
                validationErrors: [],
                id: this.instanceData ? this.instanceData.id : null,
                name: this.instanceData ? this.instanceData.name : '',
                subdomain: this.instanceData ? this.instanceData.subdomain : '',
                namePlaceholder: this.trans('instance_name_placeholder'),
                subdomainPlaceholder: this.trans('subdomain_placeholder'),
                logo: this.instanceData ? this.instanceData.logoId : null,
                buttonText: this.trans('upload_logo'),
                maxFileSize: 1000000000,
                allowedFileTypes: ['image/*'],
                maxNumberOfFiles: 1,
                isStartedFileUpload: false,
                existingUploadedFiles: [{
                    'originalName': this.instanceData ? this.instanceData.logoOrginalName : '', 
                    'downloadUrl': this.instanceData ? '/file/'+ this.instanceData.logoId +'/download' : ''
                }],
                uploadedSuccessResponse: {
                    data: {
                        s3FileId: this.instanceData ? this.instanceData.logoId : null
                    }
                },
                styles: this.instanceData ? (this.instanceData.styleContent ? this.instanceData.styleContent : '') : ".login .card-header {color: #212529; background-color: rgba(0, 0, 0, 0.03);}\n"+
                        ".btn-primary {color: #000; background-color: #3490dc; border-color: #3490dc;}"
            }
        },
        methods: {
            trans(str) {
                return trans(str);
            },
            saveInstance() {
                let url = '';
                let data = null;

                if (this.id) {
                    url = '/admin/update-instance';
                    data = {id: this.id, name: this.name, logo: this.logo, styles: this.styles};
                } else {
                    url = '/admin/create-instance';
                    data = {name: this.name, subdomain: this.subdomain, logo: this.logo, styles: this.styles};
                }

                let request = postForm(url, null, data);
                let self = this;

                request.then(function (response) {
                    self.submittedForm = false;
                    self.validationErrors = [];
                    window.location.href = '/admin/instances';
                });

                request.catch(function(error) {
                    self.submittedForm = true;
                    
                    if (error.response.status == 422) {
                       self.validationErrors = error.response.data.errors; 
                    }
                });
            },
            updateIsStartedFileUpload(val) {
                this.isStartedFileUpload = val;
            },
            updateFile(val) {
                this.logo = val;
            },
        }
    }
</script>
