<template>
    <div class="row">
        <div class="col-md-12">
            <h3>{{ trans('analysis_details') }}</h3>
            <p class="mb-0"><span>{{ trans('analysis_type') }}:</span> {{ trans(analyse.data.type) }}</p>
        </div>
        <div class="col-md-6">
            <h5 class="my-2">{{ trans('recording') }}</h5>
            <p class="mb-0"><span>{{ trans('recording_file') }}:</span> <a href="" @click.prevent="downloadFile($event,`/file/${analyse.recording_file_id}/downloadUrl`, analyse.recording_file_name)">{{ analyse.recording_file_name }}</a></p>
            <p class="mb-0"><span>{{ trans('recording_start_date') }}:</span> {{ formatDate(analyse.data.recording_start_date) }}</p>
            <p class="mb-0"><span>{{ trans('recording_start_time') }}:</span> {{ analyse.data.recording_start_time.hours }}:{{ analyse.data.recording_start_time.minutes }}</p>
            <p class="mb-0"><span>{{ trans('duration_of_recording') }}:</span> {{ analyse.data.recording_duration == 1 ? 24 : analyse.data.recording_duration }} {{ analyse.data.recording_duration == 1 ? trans('hours') : trans('days') }}</p>
            <h5 class="my-2">{{ trans('basic_information') }}</h5>
            <p class="mb-0"><span>{{ trans('gender') }}:</span> {{ trans(analyse.data.gender) }}</p>
            <p class="mb-0"><span>{{ trans('date_of_birth') }}:</span> {{ formatDate(analyse.data.birth_date) }}</p>
            <p class="mb-0"><span>{{ trans('length') }}:</span> {{ analyse.data.length }}</p>
            <p class="mb-0"><span>{{ trans('weight') }}:</span> {{ analyse.data.weight }}</p>
            <h5 class="my-2">{{ trans('lifestyles') }}</h5>
            <p class="mb-0"><span>{{ trans('usage_of_alcohol') }}:</span> {{ convertIntToStringOne(analyse.data.alcohol_usage) }}</p>
            <p class="mb-0"><span>{{ trans('smoking') }}:</span> {{ convertIntToStringOne(analyse.data.smoking) }}</p>
            <h5 class="my-2">{{ trans('medication') }}</h5>
            <p class="mb-0"><span>{{ trans('constant_medication') }}:</span> {{ analyse.data.constant_medication }}</p>
            <p class="mb-0"><span>{{ trans('research_day_medication') }}:</span> {{ analyse.data.is_research_day_medication == 0 ? trans('same_as_above') :  analyse.data.is_research_day_medication}}</p>
        </div>
        <div class="col-md-6">
            <h5>{{ trans('heart_health') }}</h5>
            <p class="mb-0"><span>{{ trans('diagnosed_heart_disease') }}:</span> {{ analyse.data.is_diagnosed_heart_disease == 0 ? trans('no') :  analyse.data.diagnosed_heart_disease}}</p>
            <p class="mb-0"><span>{{ trans('sudden_death_arrhythmias_myocardial_infarction') }}:</span> {{ analyse.data.is_sudden_death == 0 ? trans('no') :  analyse.data.sudden_death}}</p>
            <p class="mb-0"><span>{{ trans('palpitations') }}:</span> {{ convertIntToStringOne(analyse.data.palpitations) }}</p>
            <p class="mb-0"><span>{{ trans('uneven_heart_rate') }}:</span> {{ convertIntToStringOne(analyse.data.uneven_heart_rate) }}</p>
            <p class="mb-0"><span>{{ trans('bruises') }}:</span> {{ convertIntToStringOne(analyse.data.bruises) }}</p>
            <p class="mb-0"><span>{{ trans('slow_pulse') }}:</span> {{ convertIntToStringOne(analyse.data.slow_pulse) }}</p>
            <p class="mb-0"><span>{{ trans('fast_pulse') }}:</span> {{ convertIntToStringOne(analyse.data.fast_pulse) }}</p>
            <p class="mb-0"><span>{{ trans('chest_pain_during_exertion') }}:</span> {{ convertIntToStringOne(analyse.data.chest_pain_exertion) }}</p>
            <p class="mb-0"><span>{{ trans('shortness_of_breath_on_exertion') }}:</span> {{ convertIntToStringOne(analyse.data.shortness_breath_exertion) }}</p>
            <p class="mb-0"><span>{{ trans('dizziness') }}:</span> {{ convertIntToStringOne(analyse.data.dizziness) }}</p>
            <p class="mb-0"><span>{{ trans('seizures_of_unconsciousness') }}:</span> {{ convertIntToStringOne(analyse.data.seizures_unconsciousness) }}</p>
            <p class="mb-0"><span>{{ trans('blurring_of_consciousness') }}:</span> {{ convertIntToStringOne(analyse.data.blurring_consciousness) }}</p>
            <p class="mb-0"><span>{{ trans('do_you_feel_arrhythmias') }}:</span> {{ convertIntToStringTwo(analyse.data.arrhythmias) }}</p>
        </div>
        <div class="col-md-12 mt-2">
            <h5>{{ trans('others') }}</h5>
            <p class="mb-0"><span>{{ trans('main_symptom_occur_during_recording') }}:</span> {{ analyse.data.recording_main_symptom }}</p>
            <p class="mb-0"><span>{{ trans('diary_entries') }}:</span> {{ analyse.data.diary_entries }}</p>
            <p class="mb-0"><span>{{ trans('submission_text_research_topic_additional_information') }}:</span> {{ analyse.data.submission_text }}</p>
            <p class="mb-0"><span>{{ trans('comment') }}:</span> {{ analyse.data.comment }}</p>
        </div>
    </div>
    <div class="row" v-if="generalInfo.routeName == 'service_provider' && generalInfo.pageName == 'analyses_process'">
        <h4 class="bg-light mt-3 p-2 ps-0-75">{{ trans('service_provider') }}</h4>
        <div class="col-md-12 mb-3">
            <label for="comment" class="form-label">{{ trans('write_your_comment') }}</label>
            <textarea class="form-control" id="comment" rows="3" v-model="analyse.comment"></textarea>
        </div>
        <div class="col-md-3 mb-3">
            <label class="form-label">{{ trans('upload_pdf_edf_file') }}</label>
            <file-upload @uploaded="uploadedFile" buttonClasses="btn btn-info d-block" :buttonText="buttonText" :maxFileSize="this.maxFileSize" :allowedFileTypes="this.allowedFileTypes" :maxNumberOfFiles="this.maxNumberOfFiles" uploadUrl="/service-provider/upload-analysis-file" deleteUrl="/service-provider/delete-analysis-file" :customId="this.analyse.id" :existingUploadedFiles="analyse.analysis_file_id ? existingUploadedFiles : []" :uploadedSuccessResponse="analyse.analysis_file_id ? uploadedSuccessResponse : {}" />
        </div>
        <div class="col-md-3 mb-3">
            <label for="status" class="form-label">{{ trans('status') }}</label>
            <select class="form-select" id="status" v-model="analyse.status">
                <option value="2">{{ trans('in_progress') }}</option>
                <option value="3">{{ trans('pending') }}</option>
                <option value="1" :disabled="!isUploadedAnlysisFile">{{ trans('finished') }}</option>
            </select>
        </div>
    </div>
    <div class="row mt-3" v-if="generalInfo.routeName == 'client' || generalInfo.pageName == 'finished_analyses'">
        <h4 class="bg-light p-2 ps-0-75">{{ trans('service_provider') }}</h4>
        <div class="col-md-12 mt-2">
            <p class="mb-0"><span>{{ trans('analysis_file') }}:</span> <a href="" @click.prevent="downloadFile($event,`/file/${analyse.analysis_file_id}/downloadUrl`, analyse.analysis_file_name)">{{ analyse.analysis_file_name }}</a></p>
            <p class="mb-0"><span>{{ trans('comment') }}:</span> {{ analyse.comment }}</p>
            <p class="mb-0"><span>{{ trans('status') }}:</span> {{ statusName(analyse.status) }}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 mb-3" :class="[generalInfo.routeName == 'client' || generalInfo.pageName == 'finished_analyses' ? 'mt-3' : '']">
            <button type="button" class="btn btn-primary" v-if="generalInfo.routeName == 'service_provider' && generalInfo.pageName == 'analyses_process'" @click="saveAnalysis">{{ trans('save') }}</button>
            <a href="" class="btn btn-link me-3" @click.prevent="backToList">{{ trans('back_to_list') }}</a>
        </div>
    </div>
</template>

<script>
    import FileUpload from '../FileUpload.vue';

    export default {
        components: {
            FileUpload
        },
        props: {
            generalInfo: {
                type: Object
            },
            analyseData: {
                type: Object
            }
        },
        emits: ['updateActivePage', 'updateAnalyses'],
        data() {
            return {
                analyse: this.analyseData,
                maxFileSize: 1000000000,
                allowedFileTypes: ['application/pdf', '.edf'],
                maxNumberOfFiles: 6,
                buttonText: this.trans('upload_file'),
                isUploadedAnlysisFile: this.analyseData.has_analysis_file,
                existingUploadedFiles: [{
                    'originalName': this.analyseData ? this.analyseData.analysis_file_name : '', 
                    'downloadUrl': this.analyseData ? '/file/'+ this.analyseData.analysis_file_id +'/downloadUrl' : ''
                }],
                uploadedSuccessResponse: {
                    data: {
                        s3FileId: this.analyseData ? this.analyseData.analysis_file_id : null
                    }
                },
            }
        },
        methods: {
            trans(str) {
                return trans(str);
            },
            downloadFile(event, fileUrl, fileName) {
                event.preventDefault();
                let request = get(fileUrl);
                request.then(response => {
                    const link = document.createElement('a');
                    link.href = response;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
            },
            formatDate(date) {
                return formatDate(date);
            },
            convertIntToStringOne(val) {
                let str = this.trans('no');

                if (val == 0) {
                    str = this.trans('no');
                } else if(val == 1) {
                    str = this.trans('sometimes');
                } else if(val == 2) {
                    str = this.trans('frequently');
                }

                return str;
            },
            convertIntToStringTwo(val) {
                let str = this.trans('no_symptoms');

                if (val == 0) {
                    str = this.trans('no_symptoms');
                } else if(val == 1) {
                    str = this.trans('at_rest');
                } else if(val == 2) {
                    str = this.trans('under_strain');
                } else if(val == 3) {
                    str = this.trans('both');
                }

                return str;
            },
            backToList() {
                this.$emit('updateActivePage', 'list');
            },
            saveAnalysis() {
                if (!this.isUploadedAnlysisFile && this.analyse.status == 1) {
                    this.analyse.status = 2;
                }

                let request = post('/service-provider/save-analysis', {id: this.analyse.id, status: this.analyse.status,  comment: this.analyse.comment});
                let self = this;

                request.then(function (response) {
                    if (self.isUploadedAnlysisFile && self.analyse.status == 1) {
                        self.$emit('updateActivePage', 'list');
                        self.$emit('updateAnalyses', self.analyse.id);
                    }

                    toastMessage('success', response.data.message);
                });
            },
            uploadedFile(val) {
                this.isUploadedAnlysisFile = val;
            },
            statusName(status) {
                let str = '';

                if (status == 1) {
                    str = this.trans('finished');
                }

                return str;
            },
        },
    }
</script>
<style>
    .ps-0-75 {
        padding-left: 0.75rem !important;
    }
</style>
