<template>
    <div class="row">
        <div class="col-md-6">
            <h3>{{ trans('service_providers') }}</h3>
        </div>
        <div class="col-md-6 text-end">
            <a href="#" class="btn btn-primary" @click.prevent="showCreateModal">{{ trans('create_service_provider') }}</a>
        </div>
    </div>
    <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">{{ trans('name') }}</th>
                    <th scope="col">{{ trans('email_address') }}</th>
                    <th scope="col">{{ trans('instance') }}</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="serviceProviders.length > 0" v-for="(serviceProvider, index) in serviceProviders">
                    <th scope="row">{{ (index + 1) }}</th>
                    <td>{{ serviceProvider.name }}</td>
                    <td>{{ serviceProvider.email }}</td>
                    <td>{{ serviceProvider.instanceName }}</td>
                    <td><a href="" class="btn btn-sm btn-info" v-if="!serviceProvider.password" @click.prevent="resendLink(serviceProvider.email, serviceProvider.instanceId)">{{ trans('resend_link') }}</a></td>
                    <td><a href="#" class="btn btn-sm btn-secondary" @click.prevent="showEditModal(serviceProvider)">{{ trans('edit') }}</a></td>
                    <td><a href="#" class="btn btn-sm btn-danger" @click.prevent="deleteServiceProvider(serviceProvider.id, serviceProvider.name)">{{ trans('delete') }}</a></td>
                </tr>
                <tr v-else>
                    <td colspan="6" class="text-center">{{ trans('no_data_found') }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <pagination v-if="totalPages > 0" @pageChanged="onChangePage" :total-pages="totalPages" :per-page="perPage" :current-page="currentPage" />
    <service-provider-form @updateServiceProviders="updateServiceProviders" :modal-type="modalType" :modal-title="modalTitle" :instances="instances" :service-provider-data="serviceProviderData" :url="url" />
</template>

<script>
    import Pagination from '../Pagination.vue';
    import { Modal } from 'bootstrap';
    import ServiceProviderForm from './ServiceProviderForm.vue';

    export default {
        components: {
            Pagination,
            ServiceProviderForm
        },
        props: {
            serviceProvidersData: {
                type: Object
            },
            instancesData: {
                type: Object
            },
            totalRows: {
                type: Number
            },
        },
        data() {
            return {
                serviceProviders: this.serviceProvidersData,
                instances: this.instancesData,
                totalNumRows: this.totalRows,
                perPage: 10,
                currentPage: 1,
                modalType: 'create',
                modalTitle: this.trans('create_service_provider'),
                url: 'create-service-provider',
                serviceProviderData: {
                    id: null,
                    name: '',
                    email: '',
                    instance: ''
                }
            }
        },
        computed: {
            totalPages() {
                return Math.ceil(this.totalNumRows / this.perPage);
            }
        },
        methods: {
            trans(str) {
                return trans(str);
            },
            onChangePage(page) {
                this.currentPage = page;
                let offset = this.currentPage - 1;

                let request = get('/admin/service-providers?offset='+ offset);
                let self = this;

                request.then(function (response) {
                    self.serviceProviders = response.serviceProvidersData;
                    self.totalNumRows = response.totalRows;
                });
            },
            showCreateModal() {
                let createModal = new Modal(document.getElementById('serviceProviderModal'));
                createModal.show();
                this.modalType = 'create';
                this.modalTitle = this.trans('create_service_provider');
                this.url = 'create-service-provider';
            },
            showEditModal(serviceProvider) {
                let editModal = new Modal(document.getElementById('serviceProviderModal'));
                editModal.show();
                this.modalType = 'edit';
                this.modalTitle = this.trans('edit_service_provider');
                this.url = 'edit-service-provider';
                this.serviceProviderData.id = serviceProvider.id;
                this.serviceProviderData.name = serviceProvider.name;
                this.serviceProviderData.email = serviceProvider.email;
                this.serviceProviderData.instance = serviceProvider.instanceId;
            },
            updateServiceProviders(val) {
                if (this.modalType == 'create') {
                    let pageOffset = this.totalPages > 0 ? this.totalPages : 1;
                    this.onChangePage(pageOffset);
                } else {
                    for (let sp in this.serviceProviders) {
                        if (this.serviceProviders[sp].id == val.id) {
                            this.serviceProviders[sp].id = val.id;
                            this.serviceProviders[sp].name = val.name;
                            this.serviceProviders[sp].email = val.email;
                            this.serviceProviders[sp].password = val.password;
                            this.serviceProviders[sp].instanceId = val.instanceId;
                            this.serviceProviders[sp].instanceName = val.instanceName;
                        }
                    }
                }
            },
            deleteServiceProvider(id, name) {
                this.$confirm(
                    this.trans('delete_service_provider_confirm'), 
                    '', 
                    '', 
                    {
                        title: '<h4 class="mb-0">'+ this.trans('delete_service_provider') +': '+ name +'</h4>',
                        buttonsStyling: false, 
                        customClass: {
                            confirmButton: 'btn btn-primary m-1', 
                            cancelButton: 'btn btn-secondary m-1'
                        }
                    }
                ).then(() => {
                    let request = post('/admin/delete-service-provider', {id: id});
                    let self = this;

                    request.then(function (response) {
                        for (let sp in self.serviceProviders) {
                            if (self.serviceProviders[sp].id == response.data.id) {
                                self.serviceProviders.splice(sp, 1);
                            }
                        }

                        if (self.serviceProviders.length == 0) {
                            self.totalNumRows = 0;
                        }

                        toastMessage('success', response.data.message);
                    });
                }).catch((error) => {
                    
                });
            },
            resendLink(email, instanceId) {
                this.$confirm(
                    this.trans('resend_link_confirm'), 
                    '', 
                    '', 
                    {
                        title: '<h4 class="mb-0">'+ this.trans('resend_link') +'</h4>',
                        buttonsStyling: false, 
                        customClass: {
                            confirmButton: 'btn btn-primary m-1', 
                            cancelButton: 'btn btn-secondary m-1'
                        }
                    }
                ).then(() => {
                    let request = post('/admin/resend-service-provider-link', {email: email, instance: instanceId});
                    let self = this;

                    request.then(function (response) {
                        toastMessage('success', response.data.message);
                    });
                }).catch((error) => {
                    
                });
            },
        }
    }
</script>
